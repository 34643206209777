<template>
  <v-container fluid>
    <v-expansion-panel class="mb-3" inset>
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-card
          color="white"
          style="border: 1px solid #000; border-radius: 50px"
        >
          <v-layout row>
            <v-layout row wrap align-center>
              <v-flex class="mx-3">
                <v-layout row wrap align-center>
                  <v-flex>
                    <v-autocomplete
                      :disabled="ifCenterUser"
                      class="ma-2"
                      v-model="searchParam.center_code"
                      :item-text="
                        item => item.center_code + ' - ' + item.center_name
                      "
                      item-value="center_code"
                      :items="getTheoryCenterList"
                      menu-props="auto"
                      label="Select Center"
                      prepend-icon="list"
                      @change="getAbsentData()"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          close
                          @input="clearCenter()"
                          :selected="data.selected"
                          class="chip--select-multi"
                        >
                          {{ formatCenterText(data.item) }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ formatCenterText(data.item) }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Roll Number"
                      prepend-icon="list"
                      v-model="searchParam.exam_roll_no"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Regestration Number"
                      prepend-icon="list"
                      v-model="searchParam.reg_no"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Pepar Code"
                      prepend-icon="list"
                      v-model="searchParam.paper_code"
                      type="number"
                      single-line
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-text-field
                      label="Examinee Name"
                      prepend-icon="cccc"
                      v-model="searchParam.name"
                      type="text"
                      single-line
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs6 sm4 style="padding-top: 24px">
                    <v-menu :close-on-content-click="true">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="searchParam.date"
                          label="Select Exam date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="searchParam.date"></v-date-picker>
                    </v-menu>
                  </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
          <div class="text-xs-center" style="padding-bottom: 40px">
            <v-btn
              style="padding: 24px"
              round
              color="primary"
              @click="attendanceDailyWantingList()"
              dark
            >
              <v-icon left dark>search</v-icon>Search
            </v-btn>
          </div>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      style="margin-top: 50px"
      color="red darken-3"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ snackbarMessage }}
      <v-btn color="light" flat @click="snackbar = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-badge left overlap>
            <template v-slot:badge>
              <v-btn
                round
                color="primary"
                fab
                small
                style="margin-top: -1.3em"
                class="elevation-0"
                >{{
                  pagination.totalItems ? pagination.totalItems : "0"
                }}</v-btn
              >
            </template>
            <v-card-text class="title" style="margin-top:14px;"
              >Attendance Wanting List</v-card-text
            >
          </v-badge>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-layout row wrap align-center>
          <v-flex xs4 sm4 md6></v-flex>
          <v-flex xs4 sm4 md6 style="margin-top:10px; padding-right:10px;">
            <v-menu :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="todaysDate"
                  label="Select Exam date"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="todaysDate"
                @input="attendanceDailyWantingList()"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-btn
          color="primary"
          @click="downLoadTopSheet()"
          style="margin-top:22px;"
          >DOWNLOAD TOPSHEET</v-btn
        >
      </v-layout>
    </v-card>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getAttendanceDailyWantingList.data"
      class="elevation-1"
      :total-items="pagination.totalItems"
      :pagination.sync="pagination"
      :rows-per-page-items="pagination.rowsPerPageItems"
    >
      <template v-slot:items="props">
        <td class="text-xs-center">{{ props.index + 1 }}</td>
        <td class="text-xs-left" style="padding-left: 10px">
          {{ props.item.name }}
        </td>
        <td class="text-xs-center">
          {{ props.item.exam_roll_no }}
        </td>
        <td class="text-xs-center">
          {{ props.item.reg_no }}
        </td>
        <td class="text-xs-center">
          <span>
            {{ props.item.paper_code }}
          </span>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import common_getters_mixin from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_exam_lg from "../../mixin/common_login_getters.vue";
export default {
  mixins: [common_getters_mixin, commonExamYear, common_exam_lg],
  data() {
    return {
      date: "",
      todaysDate: "",
      topDown: {},
      loading: false,
      searchParam: {},
      topsheetParam: {},
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
          value: ""
        },
        { text: "Student Name", align: "center", value: "student_name" },
        { text: "Roll No", align: "center", value: "exam_roll_no" },
        { text: "Reg No", align: "center", value: "reg_no" },
        { text: "Paper Code", align: "center", value: "paper_code" }
      ],
      snackbar: false,
      timeout: 6000,
      y: "top",
      x: "right",
      mode: "",
      snackbarMessage: "Need Attendance (Present/Absent) of wanting examinees",
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, 50]
      }
    };
  },

  created() {
    if (this.ifCenterUser == true) {
      this.searchParam.center_code = this.getAuthUser.center.center_code;
    } else {
      this.searchParam.center_code = "";
    }
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.exam_year = this.getLoggedInYear.year;
    this.theworyyCenterData();
    this.currentDate();
  },
  computed: {
    getTheoryCenterList() {
      return this.$store.getters.getTheoryCenterList;
    },

    getAttendanceDailyWantingList() {
      return this.$store.getters.getAttendanceWantingList;
    }
  },
  watch: {
    pagination() {
      this.attendanceDailyWantingList();
    }
  },
  methods: {
    formatCenterText(item) {
      return item.center_code + " - " + item.center_name;
    },
    theworyyCenterData() {
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.year = this.getLoggedInYear.year;
      this.$store.dispatch("fetchTheoryWiseCenterData", param);
    },
    setLimit() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
    },
    attendanceDailyWantingList() {
      this.loading = true;
      this.setLimit();
      let param = {};
      param.page = this.searchParam.page;
      param.limit = this.searchParam.limit;
      param.sort = this.searchParam.sort;
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      param.center_code = this.searchParam.center_code;
      param.reg_no = this.searchParam.reg_no;
      param.exam_roll_no = this.searchParam.exam_roll_no;
      param.paper_code = this.searchParam.paper_code;
      param.date = this.todaysDate;
      // param.date = this.searchParam.date;
      if (this.searchParam.center_code) {
        this.$store.dispatch("fetchAttendanceWantingList", param).then(data => {
          console.log("Tanvir, this is wanting data", data);
          this.loading = false;
          this.pagination.totalItems = data.total;
        });
      }
    },
    currentDate() {
      const current = new Date().toJSON().slice(0, 10);
      this.todaysDate = current;
      console.log("Tanvir_Date", this.todaysDate);
      return this.todaysDate;
    },
    downLoadTopSheet() {
      this.topsheetParam.date = this.todaysDate;
      this.topsheetParam.center_code = this.searchParam.center_code;
      this.topsheetParam.exam_code = this.getLoggedInExam.code;
      this.topsheetParam.year = this.getLoggedInYear.year;
      this.topsheetParam.exam_initiate_id = this.getExamInitiateId;
      //this.$store.dispatch("downLoadTopSheet", this.topsheetParam);
      if (this.getAttendanceDailyWantingList.data.length == 0) {
        this.$store.dispatch("downLoadTopSheet", this.topsheetParam);
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>

<style></style>
